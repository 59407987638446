@import "./font-icons/font-icons.css";


@font-face{
     font-family: "FuturaStd-CondensedMedium";
     src: url("FuturaStd-CondensedMedium/063e9e63-1dc7-4956-a63f-5b1a36861575.eot?#iefix");
     src: url("FuturaStd-CondensedMedium/063e9e63-1dc7-4956-a63f-5b1a36861575.eot?#iefix") format("eot"),
          url("FuturaStd-CondensedMedium/55a1f1e7-eac2-45b3-874d-7eb27a46e031.woff2") format("woff2"),
          url("FuturaStd-CondensedMedium/d11fa76a-0b78-4f14-a981-1e3972870f25.woff") format("woff"),
          url("FuturaStd-CondensedMedium/64ceb11e-67fe-4274-bf6a-d060b107f1c2.ttf") format("truetype");
     font-display: swap;	
}

@font-face{
     font-family: "FuturaStd-CondensedBold";
     src: url("FuturaStd-CondensedBold/e44b85e1-3de8-4ff1-abcc-a74689e275de.eot?#iefix");
     src: url("FuturaStd-CondensedBold/e44b85e1-3de8-4ff1-abcc-a74689e275de.eot?#iefix") format("eot"),
          url("FuturaStd-CondensedBold/8c36af3c-6546-4ab9-8027-5d79fb096443.woff2") format("woff2"),
          url("FuturaStd-CondensedBold/fc1d7404-5c9a-4296-b311-e52f73503077.woff") format("woff"),
          url("FuturaStd-CondensedBold/a7a47646-76dd-4b77-a09c-ac9feebb7ab4.ttf") format("truetype");
     font-display: swap;	
}


/* open-sans-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-500italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-500italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff') format('woff'), /* Modern Browsers */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('open-sans/open-sans-v34-latin-ext_latin_cyrillic-ext_cyrillic-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* raleway-100 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-200 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-900 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-100italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-100italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-200italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-200italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-300italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-300italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-500italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-800italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-900italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  src: url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff') format('woff'), /* Modern Browsers */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('raleway/raleway-v28-latin-ext_latin_cyrillic-ext_cyrillic-900italic.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* lato-100 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('lato/lato-v23-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-100.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-100italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('lato/lato-v23-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-100italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-300 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('lato/lato-v23-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-300italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('lato/lato-v23-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('lato/lato-v23-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('lato/lato-v23-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('lato/lato-v23-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('lato/lato-v23-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900 - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('lato/lato-v23-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900italic - latin-ext_latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('lato/lato-v23-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('lato/lato-v23-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('lato/lato-v23-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('lato/lato-v23-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('lato/lato-v23-latin-ext_latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* crete-round-regular - latin-ext_latin */
@font-face {
  font-family: 'Crete Round';
  font-style: normal;
  font-weight: 400;
  src: url('crete-round/crete-round-v14-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('crete-round/crete-round-v14-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('crete-round/crete-round-v14-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('crete-round/crete-round-v14-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('crete-round/crete-round-v14-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('crete-round/crete-round-v14-latin-ext_latin-regular.svg#CreteRound') format('svg'); /* Legacy iOS */
}
/* crete-round-italic - latin-ext_latin */
@font-face {
  font-family: 'Crete Round';
  font-style: italic;
  font-weight: 400;
  src: url('crete-round/crete-round-v14-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('crete-round/crete-round-v14-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('crete-round/crete-round-v14-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('crete-round/crete-round-v14-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('crete-round/crete-round-v14-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('crete-round/crete-round-v14-latin-ext_latin-italic.svg#CreteRound') format('svg'); /* Legacy iOS */
}