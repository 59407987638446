html {
    scroll-behavior: smooth;
}

body {
	color: #333;
	font-family: 'Open Sans', sans-serif;	
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	text-transform: none;
	font-size: 1rem;
	letter-spacing: normal;
}

img {
    max-width: 100%;
    height: auto;
}

* {
    box-sizing: border-box;
}

a {
    color: #19376B;
}
