/*!
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'variables';
@import 'thirdparty/fontawesome-pro-5.15.3/scss/mixins';
@import 'thirdparty/fontawesome-pro-5.15.3/scss/core';
@import 'thirdparty/fontawesome-pro-5.15.3/scss/larger';
// @import 'thirdparty/fontawesome-pro-5.15.3/scss/fixed-width';
// @import 'thirdparty/fontawesome-pro-5.15.3/scss/list';
// @import 'thirdparty/fontawesome-pro-5.15.3/scss/bordered-pulled';
// @import 'thirdparty/fontawesome-pro-5.15.3/scss/animated';
 @import 'thirdparty/fontawesome-pro-5.15.3/scss/rotated-flipped';
// @import 'thirdparty/fontawesome-pro-5.15.3/scss/stacked';
@import 'thirdparty/fontawesome-pro-5.15.3/scss/icons';
@import 'thirdparty/fontawesome-pro-5.15.3/scss/screen-reader';

@import 'thirdparty/fontawesome-pro-5.15.3/scss/brands';
@import 'thirdparty/fontawesome-pro-5.15.3/scss/regular';
@import 'thirdparty/fontawesome-pro-5.15.3/scss/solid';
@import 'thirdparty/fontawesome-pro-5.15.3/scss/light';
/*@import 'thirdparty/fontawesome-pro-5.15.3/scss/duotone';*/