
/**********************************************************************************************/
/**********************************************************************************************/
/* Basics */
.brw						{border-right:1px solid #fff;}
.float-right				{float: right;}
.float-left				{float: left;}

.white			 		{color: #fff;}
.su-green			 		{color: #9DC84B;}
.su-blue			 		{color: #19376B;}
.linkblack	 			{color: #383838 !important;}
.linkblack:hover			{color: #000 !important;text-decoration: underline;}

.m-0-5					{margin: 0.5rem!important;}
.m-1						{margin: 1rem!important;}
.m-1-5					{margin: 1.5rem!important;}
.m-2						{margin: 2rem!important;}
.m-2-5					{margin: 2.5rem!important;}
.m-3						{margin: 3rem!important;}
.m-3-5					{margin: 3.5rem!important;}

.p-0-5					{padding: 0.5rem!important;}
.p-1						{padding: 1rem!important;}
.p-1-5					{padding: 1.5rem!important;}
.p-2						{padding: 2rem!important;}
.p-2-5					{padding: 2.5rem!important;}
.p-3						{padding: 3rem!important;}
.p-3-5					{padding: 3.5rem!important;}

.nopad 					{padding:0px;margin:0px}

html, body 				{height: 100%;font-family: 'Open Sans', sans-serif, 'FuturaStd-CondensedBold';font-size: 1rem;}

h1 		 				{font-weight: 500;font-size: 1.3rem;color: #383838;line-height: 22px;}
h2 		 				{color: #19376B;font-family: 'Open Sans', sans-serif;font-weight: 600;font-size: 1.2rem;margin-top:30px;}
h3 		 				{color: #19376B;font-family: 'Open Sans', sans-serif;font-weight: 600;font-size: 1.1rem;margin-top:30px;margin-bottom:20px;}
h4 						{font-family: 'Open Sans', sans-serif;font-weight: 600;font-size: 1.1rem;}
table 	 				{width:auto !important;} /*hebelt pareto aus, wenn 700px Breite fest definiert werden, drin lassen!*/
p 		 				{font-size: 1rem;}

button 					{border: none;background-color: rgba(153,197,71,1);font-size: 15px;color: #FFFFFF;padding: 1%;margin-right: 2%;}

.rowvalue table 			{width: inherit !important;}

.h1 						{color: #383838;font-size: 1.3rem;font-weight: 500;line-height: 22px;margin: 0.67em 0;}
.h2 						{color: #999;font-weight: normal;margin-bottom: 0.2em;line-height: 25px;font-size: 1.2rem;}
.h3 						{color: #555;font-family: 'Open Sans', sans-serif;font-weight: initial;font-size: 0.9rem;}

.h4mod					{font-family: 'Open Sans', sans-serif;font-weight: bold;font-size: 1rem;}

.font-07 					{font-size: 0.7em;}
.pt-22 					{padding-top: 22px;}

.bg-white 				{background-color: #fff;}
.fancy-title 				{margin-bottom: 30px;}
.lineline 				{border-bottom: 1px solid #9DC84B;}
.btn-primary 				{color: #fff;background-color: #5B8424;border-color: #9DC84B;border-radius: 0rem;width: 100%;}
.btn-primary:hover 			{color: #fff;background-color: #19376b;border-color: #19376b;border-radius: 0rem;width: 100%;}
.btn-primary-vf:hover 		{color: #5B8424;background-color: #fff;border-color: #fff;}
#grayout 					{position: fixed;left: 0px;top: 0px;height: 100%;width: 100%;background-color: black;opacity: 0.5;z-index: 200;}
/**********************************************************************************************/
/**********************************************************************************************/
/* allgemeines CSS */
.container-fluid 			{width:100%;padding-right: 15px;padding-left: 15px;margin-right: auto;margin-left: auto;}
#wrapper-dummy				{background-color: #fff;}
#wrapper					{margin: 0px auto;background-color: #fff;position: relative;min-height: 100%;height: auto !important;height: 100%;}
.modal 					{z-index: 3050;}
.articleimg				{border:1px solid #DDD;}

/**********************************************************************************************/
/**********************************************************************************************/
/* oberes Headerpanel */
#logo			 		{float:left;margin-left:30px;height:93px;width:130px;}
#searchform		 		{margin-top:25px;}
#searchformmobil	 		{padding-left:20px;max-width: 200px;}
#icon-su-blue		 		{padding-top: 35px;text-align: right;}
#navtoppt,#navleftpt 		{max-width: 1125px;height:50px;}
#q 				 		{top: 10px;left: 20px;height:50px;width:280px;border: 1px solid #e7e7e7 !important;font-size: 17px;padding-left: 4%;}
#lupe			 		{margin-top: 10px;width:40px;max-width: 150%;}

.mobibermap 		 		{color: #9DC84B;}
.typeahead 		 		{z-index: 3000;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Mainmenu */
#header 					{background-color: #fff;width: 100%;z-index: 100;}
#headmenu 				{background-color: #fff;height: 116px;}
#topmid 					{max-width: 1125px;}
#topnav 					{z-index: 1000;position: fixed;top: 110px;}
#primary-menu-trigger		{z-index: 200;}

#topnav 					{line-height: 40px;height: 50px;background-color:#19376b;width: 100%;}
.nav1 a 					{color: #fff;float: left;display: block;padding: 5px 5px;text-decoration: none;font-size: 17px;}
.nav1Button				{padding: 0px 5px 0px 15px;}
.topnav a:hover 			{background-color: #fff;color: #19376B;}
.topnav a.active 			{background-color: #19376B;color: white;}
.topnav .icon 				{display: none;padding-left:20px;}
.topnav .icon:hover 		{color: #fff;background-color: #19376B;}

.submenu 					{display: none;float: left;line-height: 15px;color: #000000;font-weight: 400;text-transform: none;
						font-size: 1rem;letter-spacing: normal;position: relative;background-color: #fff;z-index: 2100;min-width: 300px;}
.subsubmenu 				{display: none;max-width: 280px;float: left;line-height: 15px;color: #000000;font-weight: 400;
						text-transform: none;font-size: 1rem;letter-spacing: normal;position: absolute;margin-top: -37px;border-top: 1px solid #19376B;background-color: #fff;min-width: 200px;}
.submenu a 				{text-decoration: none;}
.dropdown-menu 			{border-radius: 0px !important;}

.nav1					{color: #f2f2f2;}
.nav2, .nav3, .mobilenav2, .mobilenav3 {color: #000;border-bottom: 1px solid #9DC84B;border-left: 1px solid #9DC84B;
						border-right: 1px solid #9DC84B;padding: 10px;}
.nav2:hover,.nav3:hover 		{color: #fff;background-color: #19376B;}
.mobilenav2, .mobilenav3		{color: #fff;border-left: 0px;border-right: 0px;}

#mobilemenu				{color: #fff;background-color: #19376B;display: none;margin-top: 45px;}
#mobilemenu a 				{color: #fff;text-decoration: none;}

.mobilesubmenu, .mobilesubsubmenu{display: none;}
.mobinav1					{padding: 10px 20px 10px 30px;border-bottom: 1px solid #fff;}
.varietynav 				{white-space: nowrap;color: #000;padding: 5px 0px 5px 10px;border-bottom: 1px solid #9DC84B;}
.varietynav2 				{white-space: nowrap;color: #000;padding: 5px 0px 5px 10px;font-size: 0.9rem;}
.varietynav2:hover			{background-color: #19376B; color: #fff;}

.globe					{padding-top: 10px !important;position: relative;margin-right:10px;}
.globemobi				{float: right !important;display: block !important;padding-left: 15px !important;padding-top: 2px !important;}
.dropdown-item				{color: #000 !important;}
.salesunits a				{background-color: transparent !important;}
.salesunits a:hover			{color: #fff !important; background-color: #19376b !important;}
.salesunits:hover			{color: #fff !important;background-color: #19376b !important;}
.salesunits				{color: #19376b !important;background-color: #fff !important;cursor: pointer;}
.shopmore 				{display: none;}
.menufas:hover				{color: #9DC84B;}
/**********************************************************************************************/
/**********************************************************************************************/
/* Megamenu */
#megahead 				{background-color: #fff;}
#megamenu					{width: 100%;max-width: 100%;}
.menu-large				{top: 167px;width: 100%;max-width: 1080px;position: absolute;padding-right: 12px;}
.dropdown-menu				{width: 100%;max-width: 100%;z-index: 1500;margin-top: -5px;margin-left:10px;}
.navbar-nav .dropdown-menu	{width: 100%;max-width: 100%;}
.dropdown-header			{font-size: 17px;color: #383838;line-height: 40px;border-bottom: 1px solid #9DC84B;font-weight: 600;padding: 0;}
.dropdown-entry:hover 		{color: #9DC84B !important;background-color: #efefef;}
.dropdown-header-link:hover 	{color: #9DC84B !important;background-color: #efefef;}

/* Neues Megamenu Cindy 2023 */
#icon-su-white		 		{padding: 3px 5px 0px 5px;}
.globe-rd 				{padding: 5px 5px 0px 5px;}
#mainpanel				{display: none;border:1px solid lightgrey;background-color: #fff;max-width: 1080px;margin-top: 2px;position: relative;left: -17px;}
.mainpanel				{width: 100%;background-color: #fff;}
.subpanel 				{width: 300px;}
#mainpanel .nav2, .nav3, .mobilenav2, .mobilenav3 {border-right: none;border-left: none;}
#mainpanel .nav3 			{border-bottom: none;}
#mainpanel .subsubmenu 		{border-top:none;z-index: 2500;margin-top: 0px;left:320px;}
.submenuicons 				{display: none;color: #000000;z-index: 2100;min-width: -webkit-fill-available;}
.fa-desktop-size 			{font-size: 3rem;}
#megamenumobitrigger 		{float: inline-start;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Sonstige Menus */
#anchormenu				{z-index: 350;position: absolute;}
#varfinder_anchormenu		{z-index: 350;overflow: auto;}
#vf-anchorcontent 			{display: none;overflow-y: auto;width: 340px;height: inherit;margin-left: 10px;}

#vf-anchorcontent::-webkit-scrollbar {width: 1em;background-color: #19376b;}
#vf-anchorcontent::-webkit-scrollbar-thumb {background-color: #19376b;}
#vf-anchorcontent 			{scroll-snap-type: y mandatory;scroll-snap-type: mandatory;scrollbar-color: #9DC84B #19376b; /** Firefox **/}

#vf-anchorform 			{scroll-behavior: auto;width: 100%;}
#vf-anchor 				{left: 0px;width: 350px;height: 270px;}
#vf-anchorimage 			{width: fit-content;}
#toggleAnchorPermanentDiv 	{position: relative;margin-left: 15px;top: 10px;}

#closeanchor 				{float: right;color: #19376B;cursor: pointer;margin-right: 20px;}
@-webkit-keyframes slide 	{100% { left: 0; }}
@keyframes slide 			{100% { left: 0; }}

.has-children:hover			{text-decoration: none;}
.sub3link 				{text-decoration: none;}
.sub3link:hover 			{text-decoration: none;background-color: #9DC84B;}

.w285 					{width: 285px;}
.fal						{color: #fff;}
.rotate-45 				{-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-ms-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);transform: rotate(-45deg);font-size: 1.6em;}
.subrattitle 				{background-color: #9DC84B;color: #ffffff;}

/**********************************************************************************************/
/* neuer Karriere-Bereich 2023, Anne */
.h2_karriere 				{font-weight: 800;font-size: 1.2rem;color: #19376B;line-height: 22px;}
.btn_karriere 				{background-color: #9DC84B;color: #000;width: auto !important;padding: 10px 25px 10px 25px;}
.m_karriere 				{margin: auto;}
.card_header_karriere		{background-color: #d4d4d4 !important;}
.card_header_karriere button 	{color: #000 !important;}
.accordion_karriere 		{background-color: #d4d4d4 !important;height: 60px;margin: auto;vertical-align: middle;line-height: 60px;padding-left: 30px;
						font-size: 1.1rem;font-weight: 500;}
.card_karriere 			{border-radius: 0px;}
.card_karriere ul 			{padding-left: 25px;}
.card-header .accicon 		{float: right;font-size: 20px;width: 1.2em;}
.gesellschafter_karriere 	{padding: 30px;}
.gesellschafter_karriere_img 	{max-width: 100%;padding: 30px;border: 1px solid #000;}
.layerdivright 			{opacity: 0.8;background-color: #fff;left: 60%;width: 35%;position: relative;top: 20%;padding: 10px;}
.layerdivleft	 			{opacity: 0.8;background-color: #fff;left: 5%;width: 35%;position: relative;top: 20%;padding: 10px;}
.karriere_green 			{background-color: #9ec84c;color: #000;padding: 30px;}
.kontaktbox_karriere 		{border: 1px solid #9DC84B;margin: auto;padding: 20px;}
.kontaktbox_karriere_info 	{margin: auto;}
.kontakt_karriere_img 		{min-height: 263px;margin: auto;line-height: 263px;align-content: space-around;display: grid;}
.karriere_box 				{border:1px solid #707070;padding: 20px;}
.karriere_titel 			{font-weight: bolder;color: #19376B;}
.karriere_date 			{font-size: 0.9rem;color: #19376B;}
.karriere_doc 				{padding-left: 40px;}
.karriere_h2 				{font-size: 1.0rem;}
.karriere_link				{color: #9DC84B;}
.jobs-carousel-caption		{opacity: 0.8;background-color: #fff; position: absolute;right: 15%;bottom: 20px;left: 15%;z-index: 10;padding-top: 20px;padding-bottom: 20px;color: #000;text-align: center}

.karriere_circle 			{background: #9DC84B;border-radius: 50%;height: 100%;width: 100%;min-width: 110px;min-height: 150px;
						display: block;padding-top: 30px;color: #fff;border: 1px solid #fff;border-style: dashed;}
.karriere_circle_mobi		{background: #9DC84B;border-radius: 50%;height: 100%;width: 100%;min-width: 110px;min-height: 100px;
						display: block;padding-top: 15px;color: #fff;border: 1px solid #fff;border-style: dashed;}
.karriere_tcountt			{font-weight: 600;color: #19376B;}
.karriere_benefits_text		{height: 50px;display: block;}
.karriere_benefits_icons 	{font-size: 50pt;}
.karriere_benefits_inner 	{background-color: #d4d4d4;padding: 15px;width: 95%;}
.karriere_benefits_divider 	{border-top: 1px solid #19376B;}
.karriere_boxtitle			{width: 100% !important;text-align: center;}
.karriere_teasertext 		{border-left: 1px solid #d4d4d4;border-right: 1px solid #d4d4d4;}
.karriere_countertext 		{font-size: 3.5rem;}
.karriere_countertext_mobi	{font-size: 3rem;}

.btn-link:not(.collapsed) .rotate-icon {transform: rotate(180deg);}	
.m-teaserkarriere div 		{padding-left: 1.5rem !important;padding-right: 1.5rem !important;}
.m-teaserkarriere 			{min-height: 425px;}
/* /neuer Karriere-Bereich 2023, Anne */
/**********************************************************************************************/
/* Sidebar */
.leftside__font 			{color: #19376B;font-family: 'Open Sans', sans-serif;font-weight: bold;font-size: 1.1rem;}
.varfhp					{float: right;margin-top: 4px;}
.icr-label				{color:#fff;font-size: 0.9em;cursor: pointer;}
.sortenicon				{margin-right:5px;padding-left:15px;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Eventtimer */

#eventtimercls 			{width: fit-content;float: right;}
#eventtimer_close 			{color: #19376b;}
#eventtimerwrapper 			{display: none;width: 100%;align-content: center;position: fixed;bottom: -250px;max-width: 1906px;
						z-index: 500;}
#eventtimermenu 			{z-index: 501;width: fit-content;border:1px solid #19376b;}
#eventtimercontent 			{width: auto;padding-right: 5px;background-color: #fff;padding: 15px;}
#eventtimer_timeline 		{background-color: #fff;padding: 20px;min-width: 300px;margin: 15px;}
#eventtimer_novinka 		{background-color: red;color: #000;max-width: fit-content;float: right;margin-right: 15px;padding: 2px;}
.eventtimerevent 			{color: #19376b;font-weight: bold;font-size:16pt;margin-left: 15px;}
.eventtimerevent2 			{color: #19376b;font-size:14pt;margin-left: 15px;}
.eventtimerevent:hover 		{color: #000;}
.eventtimer_spacer 			{width: 4px;max-width: 4px;padding: 0px !important;margin: 0px !important;background-color: #fff;}
.eventtimer_spacer_wide 		{width: 15px;max-width: 15px;padding: 0px !important;margin: 0px !important;background-color: #fff;}
.eventtimer_grey 			{background-color: #f3f4f5;color: #19376b;font-size: 40pt;text-align: center;font-weight: 800;width: 20px;}
.eventtimer_text 			{padding-top: 5px;text-align: center;color: #000;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Content */
#innercontent	 			{padding-bottom: 178px;margin-top: 10px;width:100%;}
#tcontentright	 			{max-width: auto;}/*max-width:auto wird für oberes menu gebraucht*/ /*margin-top: 30px;*/
#breadcrumps 	 			{font-size: 0.8em;margin-top: 15px;}
#homeplz		 			{width:240px; font-size:16px; height:40px;border-radius:4px;background-color: #fff; border: 1px solid #aaa;}
#vfsubmit		 			{padding-top: 10px;width:20px; vertical-align:middle; cursor:pointer;float: right;}
#varfinder_home 			{background-color: rgb(231, 231, 231);max-width:100%;}
.varfinder-inner			{margin-left: 30px;margin-right: 30px;padding-bottom: 20px;padding-top: 20px;}

.h3_mb 					{color: #19376B;font-family: 'Open Sans', sans-serif;font-weight: bold;font-size: 1.1rem;}
.imgvarfhome				{width: 100%;}
.aktuell					{margin-bottom: 15px;}
.back 					{background-color: #9DC84B;padding: 4%;}
.bold 					{font-weight: bold;}

#document div.imgdoc 		{border: 1px solid #e2e8ec;font-size: 8pt;font-style: italic;font-weight: normal;padding: 10px;}

#document div.imgright 		{float: right;margin-left: 8px;}
#document div.imgleft  		{float: left;margin-right: 8px;}
#document a  				{text-decoration: underline !important;}
.imgdoc 					{font-size: 8pt;font-style: italic;font-weight: normal;padding: 10px;}
.imgright 				{float: right;margin-left: 8px;}
.imgleft 					{float: left;margin-right: 8px;}
.digest 					{padding: 20px;border-color: #96C038;border-width: 2px;border-style: solid;position: relative;}
#digestjumper 				{border: 1px solid #9dc84b;margin: 10px;width: 100px;font-size: 0.8em;float: right;text-align: center;
						background-color: #19376B;}
#digestjumper a			{color: #fff;}
#digestjumper a:hover		{text-decoration: underline;}
div.wissen 				{position: absolute;max-width: 200px;padding: 10px 20px;bottom: -2px;right: -2px;background-color: #19376B;
						border-bottom: 18px solid #9DC84B;color: #FFFFFF;font-size: 13px;text-decoration: underline;}

li.contactleft 			{list-style: none;border-bottom: 1px solid rgba(0,0,0,.125);}
.contact, .contactleft 		{border: none;border-radius: 0px;color: #383838;text-decoration: none;}
.contactleft:hover 			{text-decoration: none;background-color: #19376B;color:#fff;}
 
#tcontentright .progress 	{width: 100%;}/*width:auto zerstört die sortendetailansicht*/
.carousel-item-left 		{display: none;}
.bloganchor 				{display: block;position: sticky;top: 50px;overflow: visible;z-index: 50;}
.hdtxts 					{height: 0px;display: none;}
.protectimg 				{border: 1px solid #ced4da;width: 140px;position: fixed;right: 0px;z-index: 20;bottom: 15px;}

#mainslider iframe 			{width:100%;}
#mainslider row 			{max-width: 1075px;}

.errorsearchbox 			{border: 1px solid #e7e7e7!important;font-size: 17px;height: 50px;left: 20px;padding-left: 4%;top: 10px;width: 280px;}
.select2 img 				{max-width:20px;}
.namecarousel 				{font-size: 0.9em;}
div#document td 			{background-color: #F0F0F0;padding: 2px 2px 2px 5px;}
div#document table 			{margin-bottom: 15px;margin-top: 35px;}

.blogimage 				{border: 1px solid #e2e8ec;padding: 10px;margin-top: 10px;}
#eventpopup 				{max-width: 510px;}
#popupheight 				{overflow-y: auto;height: 650px;background-color: #193768;}
#closemodal 				{top: 5px;right:5px;position: relative;cursor: pointer;float: right;}
#popupimage 				{max-width:500px;}

.h-contentdiv a 			{color: #00407c;}
.h-contentdiv 				{padding: 10px;border: 1px solid #00407c;}
.artcontent ul 			{margin-left:0;padding-left:0;}
.artcontent .sec2 			{font-size: 12pt;}
.artcontent .sec3 			{padding-left:40px !important;font-size: 11pt;}
.artcontent .sec4 			{padding-left:60px !important;font-size: 10pt;}
.artcontent .sec5 			{padding-left:70px !important;font-size: 9pt;}
.artcontent .sec6 			{padding-left:80px !important;font-size: 8pt;}
.anchorlink 				{text-decoration: none;}
.anchorlink:hover			{text-decoration: underline;}
.writervcard 				{background-color: rgba(0,0,0,.03);border: 1px solid rgba(0,0,0,.125);padding: 10px;margin: 0px;}
.tab-content 				{border: 1px solid #DDD;border-top: 1px solid #fff;padding: 5px 2%;}
.morevarieties  			{height: 80px;}
caption 					{text-align:center;caption-side:top;}

.varietylink 				{text-decoration: underline;}
/**********************************************************************************************/
/**********************************************************************************************/
/*Downloadcenter*/
.dlc-block 				{padding: 15px;border: 1px solid #DDD;text-align: center;color: #000;color: #9DC84B;}
.dlc-rows 				{display: none;border-left: 1px solid #ddd;border-bottom: 1px solid #ddd;border-right: 1px solid #ddd;}
.showlong_dlc 				{cursor: pointer;color: #9DC84B;}
.dlc-cols4 				{border-bottom: 1px solid #ddd;}
.dlc-title 				{min-height: 90px;height: 90px;color: #19376B;font-family: 'Open Sans', sans-serif;font-weight: bold;
						font-size: 1rem;margin-bottom: 10px;}
.dlc-block:hover 			{color: #19376B;cursor: pointer;}
.dlc-block-active 			{color: #19376B;font-weight: 600;}
.dlc_image 				{min-height: 465px;margin: auto;background-color: #e2efcb;align-items: center;display: flex;border:1px solid #9DC84B;}

/**********************************************************************************************/
/**********************************************************************************************/
/*Newslayer*/
.darkblue 				{background-color: #19376B;}
#newslayer 				{display: none;position: fixed;bottom: -250px;right: 0px;z-index: 555;width: 350px;background: #9DC84B;padding: 10px;
						border-radius: 5px;color: #fff;border: 1px solid #19376B;}
#newslayerend 				{display: none;position: fixed;bottom: -250px;right: 0px;z-index: 666;width: 350px;background: #9DC84B;padding: 10px;
						border-radius: 5px;color: #fff;border: 1px solid #19376B;}
.buttonNL 				{border: 1px solid #19376B;border-radius: 5px;padding: 5px 15px 5px 15px;margin-top: 10px;}
.nlclose 					{position: absolute;right: 5px;top: 5px;cursor: pointer;}
#newslayerForm 			{display: none;position: fixed;bottom: 0px;right: 0px;z-index: 655;width: 350px;background: #9DC84B;padding: 10px;
						border-radius: 5px;color: #fff;border: 1px solid #19376B;}
#newslayerForm .form-control 	{margin: 5px;height: 25px;font-size: 0.8em;padding: 2px;}

/**********************************************************************************************/
/**********************************************************************************************/
/*Neue Startseite*/
.varfinderbox_font1 		{font-size: xx-large;margin-bottom: 15px;color: #fff}
.varfinderbox_font2 		{font-size: x-large;margin-bottom: 15px;color: #fff;}
.varfinderbox_font3 		{font-size: larger;	margin-bottom: 30px;color: #fff;}
.varfinderselect-side:before 	{content : "";position: absolute;left: 0;bottom: 0;height: 100%;width: 1px;}
.varfinderselect-side 		{width: 40px;position: absolute;top: 0px;right: 15px;pointer-events:none;z-index: 15;margin-top: 1px;}
.select2-selection--single	{border-radius: 0px !important;}
.eventblock_date 			{background-color: #19376B;color: #fff;font-size: 14pt;padding: 5px 15px 5px 15px;max-width: fit-content;}
.eventblock_info1 			{color: #19376B;font-size: 16pt;padding-right: 15px;}
.eventblock_info2 			{color: #000;font-size: 13pt;margin-top: 15px;}
.beraterblock 				{background-color: #19376B;color: #fff;}
.beraterblock_header		{background-color: transparent !important;color: #fff;font-size: x-large;}
.termineblock_header 		{color: #19376B;font-size: x-large;margin-left: 15px;}
.dlblock_header 			{color: #19376B;font-size: x-large;}
.dlblock_title 			{font-weight: 800;}
.beraterblock_text 			{font-size: 14pt;color: #fff;}
.carousel-caption 			{right: 30%;left: 5%;width: 310px;text-align: left;top: -35px;}
.owl-prev  				{background-color: transparent !important;}
.EventscustomPrevBtn 		{float: left;position: relative;bottom: 160px;left: -40px;}
.EventscustomNextBtn 		{float: right;position: relative;bottom: 160px;right: -20px;}
.AktuellcustomPrevBtn 		{float: left;position: relative;bottom: 320px;left: -40px;}
.AktuellcustomNextBtn 		{float: right;position: relative;bottom: 320px;right: -20px;}
.DownloadscustomPrevBtn 		{float: left;position: relative;bottom: 380px;left: -40px;}
.DownloadscustomNextBtn 		{float: right;position: relative;bottom: 380px;right: -20px;}
.carousel-newhome 			{bottom: 15px;left: auto;}
.carousel-newhome .active	{width: 15px;height: 15px !important;border-radius: 100% !important;background-color: #19376B !important;
						border-color: #19376B !important;opacity: 9 !important;}
.carousel-newhome li 		{width: 15px;height: 15px;border-radius: 100%;background-color: transparent !important;border-color: #fff !important;}
.carousel-text-overlay 		{position: relative;width: 100%;transform: translateY(-50%);bottom: 150px;z-index: 999;}
.carousel-text-wrapper 		{width: 100%;text-align: center;}
.carousel-text 			{width: 40%;height: 25%;color: #fff;background-color: #19376B;background: #19376B;margin-left: 15px !important;
						margin-right: 15px !important;min-height: 100px;padding: 15px;font-size: 18pt;bottom: -150px;}
.indicators-newhome 		{width: 40%;height: 25%;color: #fff;margin-left: 15px !important;margin-right: 15px !important;min-height: 20px;
						padding: 15px;top: 0px !important}
.carousel-indicators 		{position: absolute;right: -135px;float: right;text-align: right;}
.carousel-indicators li  	{box-sizing: inherit;width: 15px !important;height: 15px !important;border: 1px white solid;}
.h3_mb 					{color: #19376B;font-family: 'Open Sans', sans-serif;font-weight: bold;font-size: 1.1rem;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Varfinder */
.varfindername 			{font-weight: 600;font-size: 1.3em;text-transform: capitalize;color: #BE2315;line-height: 1.2;float: left;
						margin-left: 6px;}
.varfinderimg  			{max-height: 154px;}
.sname 					{font-weight: 600;text-transform: capitalize;color: #BE2315;line-height: 1.2;margin-bottom: 0px;
						margin-top: 5px;min-width: 145px;}
.snamebig					{font-weight: 600;text-transform: capitalize;color: #BE2315;line-height: 1.2;font-size:1.3em;
						margin-bottom: 0px;margin-top: 5px;min-width: 145px;}
.progress					{background-color: transparent;border-radius: 0px;height:15px;margin-bottom:1px;float: right;
						margin-right: 2px;display: flex;width: 100%;}
.progressvf				{background-color: transparent;border-radius: 0px;height:15px;margin-bottom:1px;padding-left:10px;float: right;
						margin-right: 2px;display: flex;width: auto;}
.progress-bar				{background-color: #83AD25;border: 1px solid #ffffff;height: 0.8rem;}
.progress-bar-light			{background-color: #C2D883;border: 1px solid #ffffff;height: 0.8rem;}
.progress-bar-dark			{background-color: #5B8424;border-bottom: 1px solid #ffffff;height: 0.8rem;}
.progress-bar-height		{background-color: #9DC84B;border: 1px solid #ffffff;color: #ffffff;height: 2rem;display: flex;
						flex-direction: column;justify-content: center;text-align: center;white-space: nowrap;transition: width 0.6s ease;}
.susagesmall 				{font-size: 0.8em;color: #777;line-height: 1.2;margin-top:4px;}
.szusatz					{margin-bottom: 10px;font-weight: 600;font-size: 0.7em;color: #BE2315;line-height: 1.1;margin-top: 2px;}
.btn_weiteresorten 	 		{min-height: 160px;}
.selection span 			{color: #9dc84b;}
.rollover 				{display: none;border:1px solid #96c038;width: 252px;position: absolute;top: 122px; z-index: 105;
						color: #383838;font-weight: 400;border-bottom: 1px solid #96c038;background-color: #fff;margin: auto;
						line-height: 30px;margin-top: 10px;}
.sortendrop:hover 			{background-color: #19376b;color: #fff;cursor: pointer;}
.sortendrop 				{background-color: #fff;color: #19376b;border-bottom: 1px solid #96c038;padding-left: 5px;}
.sortentitle 				{position: relative;padding: 3px 0 0 5px;top: -33px;background: #19376b;color: #fff;
						margin-bottom: 0px;padding-bottom: 0px;line-height: 30px;overflow: hidden;width: 99%;}
.sortentitle:hover 			{background: #9DC84B;color: #19376B;}
#interferer 				{background-color: #c61408;box-shadow: 3px 3px 4px #000000;color: #fff;min-height: 20px;
						padding: 10px 8px;width: 60%;float: right;font-size: 1.1em;left: 2rem;position: relative;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Sortendetail */
.clickvars		       	{float: right;padding-top: 10px;width: 100%;padding-right: 15px;}
.varietytcontent 	       	{border: 1px solid #DDD;border-top: 0px;padding: 0px 10px 10px 10px;margin-top: 0px !important;}
.strongpoints 			  	{color:red;}
.nav-link 		       	{display: block;padding: 0.5rem 1rem;font-size: 0.9em;color:#444;}
.progressdetail 	       	{border-radius: 0px;height: 1rem;background-color: transparent;font-size: 0.9rem;display: flex;overflow: hidden;}
.redbullets span 	       	{color: #444;font-size: 0.9em}
.backcolor 		       	{background-color: #EDF3DA;padding: 2% 4%;font-size: 0.9em;}
.bluebar 			       	{background-color: #19376B;padding: 1vh;margin: 2% 0%;font-size: 0.8em;}
div.rowdisrupt2 	       	{width: auto;padding: 7px 10px;background-color: #c61408;color: #fff;font-size: smaller;font-size: 0.8em;}
.detail-progress 	       	{height: auto;}
.scale-desc		       	{font-size: 1em;line-height: initial;}
.varietiesgreenfield       	{background-color: #EDF3DA;padding: 2% 4%;font-size: 0.8rem;}
.variety-detail-zebra      	{background-color: #EDF3DA;}
.text-muted 		       	{padding: 10px;border: 1px solid #6c757d;font-size: 0.8em;}
.italicline 		       	{font-style: italic;font-size: 0.8em;}
.var-distributor 	       	{margin-bottom: 15px;border-bottom: 1px solid #96c038;}
.distri_list 		       	{float:left;}
.var_title 		       	{font-size: 0.8em;}
#varcont_1 .carousel-inner 	{border: 5px solid #FFFFFF;box-shadow: 0 0 5px #cccccc;padding: 5px;}
.colblue 			       	{color: #013068;font-weight:500;text-shadow: 0;margin-top: -1px;float:left;padding:0 10px;font-size: 0.8em;}
.cgrey  			       	{color: #999;margin-top: -1px;float:left;padding:0 10px;font-size: 0.8em;}
.cgrey:hover		       	{color: #999;}

.lisort 			       	{display: inline;float: left;list-style: outside none none;padding: 0;margin: 0;width: 100%;}
.lisort li 		      	{float:right;padding-left:10px;}
.lisort li:last-child      	{border-right:1px solid #96C038;padding-right:10px;padding-left:0px;}
.lisort li img  	       	{float:left;}
#varietyspinner 			{float: right;padding-top: 10px;width: 100%;}
#varietyprintdiv 			{text-align: right;}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {background-color: lightgrey !important;}
.nav-tabs .nav-link:hover 	{background-color: lightgrey !important;}
.nav-tabs .nav-link, .nav-tabs .nav-item .nav-link {border-color: #e9ecef #e9ecef #dee2e6;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Cookie Consent */
#cookieconsent 			{height: 110%;}
.cookiehelp,.cookiedetails	{display:none;z-index: 99999;cursor: pointer;}
.cookieconsentClose 		{float: right;cursor: pointer;font-size: 0.8em;background-color: #fff;}
.cookieheaderinfo 			{width: 100%;background-color: #5B8424;color: white;font-size: 1.3em;height: 42px;padding-left: 10px;
						padding-top: 8px;padding-right: 10px;}
.cookieconsent, .cookiedetails, .cookiehelp {position: fixed;top: 10%;width: 90%;font-size: 0.8em;color: #000;padding-left: 10%;z-index: 99999;}
.cookietextright 			{text-align: right;}
.cookiewhite 				{background-color: #fff;padding: 10px;}
.cookieradiustop 			{border-top-left-radius: 10px;border-top-right-radius: 10px;}
.cookieradiusbottom 		{border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;}
.cookiewhitebg  			{background-color: #fff;}
.cookiebtn 				{border: 1px solid white;width: 100%;background-color: #5B8424;}
.cookiebtn:hover 			{background-color: #6c757d;}
.cookiebd 				{border-bottom: 1px solid #96C038 !important;text-align: left;}
.cookietl 				{text-align: left;}
.cookieconsentbtact 		{color: #666;background-color: #ccc;}
.tgroup_content 			{display: none;}

/* The switch - the box around the slider */
.switch 					{position: relative;display: inline-block;width: 60px;height: 34px;margin-left: 25px;float: right;}
.switchindiv 				{position: relative;top: -40px;right: 10px;display: inline-block;width: 60px;height: 34px;margin-left: 25px;float: right;}
/* Hide default HTML checkbox */
.switch input 				{display:none;}
/* The slider */
.slider 					{position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color: #ccc;
						-webkit-transition: .4s;transition: .4s;}
.slider:before 			{position: absolute;content: "";height: 26px;width: 26px;left: 4px;bottom: 4px;background-color: white;
						-webkit-transition: .4s;transition: .4s;}
.success 					{display: none;}
.slider.round 				{border-radius: 34px;}
.slider.round:before 		{border-radius: 50%;}

input.success:checked + .slider {background-color: #8bc34a;}
input:checked + .slider:before  {transform: translateX(26px);}



/**********************************************************************************************/
/**********************************************************************************************/
/* Company == neues Wir-über-uns */

#companymodule a:hover		{text-decoration: none;}
#company_img_layer 			{position: absolute;bottom: 15px;right: 15px;max-width: 600px;}
#company_img_layer1 		{background-color: #19376b;color: #9DC84B;font-size: 16pt;font-weight: 600;}
#company_img_layer2 		{background-color: #fff;color: #19376b;font-size: 18pt;padding:0.5rem 0 0.5rem 1rem;font-weight: 600;}
#company_img_layer3 		{background-color: #9DC84B;color: #19376b;font-size: 12pt;}
#company_logo 				{position: absolute;bottom: 5px;right: 20px;}
.company_teasertext 		{color: #5B8424;font-weight: 600;}
.card_header_company button 	{color: #19376b !important;}	
.company_txt_layer_green		{background-color: #9DC84B;font-size: 1.5em;color: #fff;position: relative;top: -50px;
						width: fit-content;padding: 20px 60px 20px 60px;}
.company_txt_layer_blue		{background-color: #19376b;font-size: 1.5em;color: #fff;position: relative;top: -50px;
						width: fit-content;padding: 20px 60px 20px 60px;}
.company_txt_layer_white		{background-color: #fff;font-size: 1.5em;color: #000;position: relative;top: -50px;
						width: fit-content;padding: 20px 60px 20px 60px;border: 1px solid black;}
#company_layer_cultures 		{font-size: 1.3em;color: #fff;position: absolute;}

.company_bg_green			{background-color: #9DC84B;font-size: 1.5em;color: #fff;}
.company_bg_blue			{background-color: #19376b;font-size: 1.5em;color: #fff;}
.company_bg_white			{background-color: #fff;font-size: 1.5em;color: #000;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Footer */
#footer 					{background-color: #9DC84B;bottom:0px;width:100%;left:0px;z-index:100;height:375px}
#footer-inner				{background-color: #9DC84B;}
.footerend				{padding-bottom: 40px;}

/**********************************************************************************************/
/**********************************************************************************************/
/* Media Queries */
@media screen and (min-width: 800px) {
	.mobi		{display: none;}
	/*#mobilemenu{display: none;}*/
	#searchdiv 	{margin-top: 15px;}
	.eventitem 	{width: 370px !important;}
}

@media screen and (max-width: 800px) {
	#burgericonm    		{width: 40px;float: left;padding-top: 10px;}
	#innercontent 	 		{position: relative;width: inherit;}/*sonst zu schmal auf Sortendetail*/
	#logo		 		{margin-left:0px;}
	.mobinot		 		{display: none;}
	#tcontentright	 		{order:1;}
	#tcontentleft 	 		{order:2;}
	#icon-su-blue	 		{display: none;}
	#searchmobil 	 		{align-content: flex-end;margin-top: 3px;height: 35px;width: 100%;z-index: 1000;}
	#qmobil 		 		{height: 30px;border: 1px solid #CCCCCC; z-index:1009;width: 200px;}
	/*.topnav a:not(:first-child) {	display: none;}*/
	.nav1 a 		 		{padding:0px;}
	.topnav a.icon  		{float: left;display: block;}
	.brw			 		{border-right: 0px !important;}
	.nav1Button	 		{display:none;}
	.nav2		 		{color: #fff;}
	.globe		 		{float: right;right: 15px;left: unset;}
	#lupemobi 	 		{position: absolute;right: 15px;top: 8px;font-size: 20pt;}
	#eventpopup	 		{max-width: 440px;margin-top: 50px;}
	#popupheight 	 		{height: 480px;}
	#popupimage 	 		{max-width:300px;}
	.flasch 		 		{margin-bottom: 40px;float: none !important;}
	#vf-anchor 	 		{width: inherit;}
	#vf-anchorimage 		{float: right;padding-right: 5px;}
	#vf-anchorcontent 		{width: 90%;}
	#varfinder_anchormenu 	{right: 0;}
	
	.carousel-text-overlay 	{bottom: 0px;transform: none;}
	.carousel-text   		{bottom: 0px;}
	.slidertextmobi 		{background-color: #013068;color: #fff;font-size: 1.3em;padding: 10px;}
	#TabVarieties 	  		{margin-top: 30px;margin-bottom: 30px;}
	#TabVarieties li 		{width: 100%;font-size: 1.2em;background-color: #013068;color: #fff;padding: 10px;}
	#TabVarieties button 	{width: 100%;font-size: 1.2em;background-color: #013068;color: #fff;padding: 10px;}
	#varietyspinner  		{float: right;padding-top: 10px;width: 100%;}
	#varietyspinner li 		{width: 50%;font-size: 1.2em;}
	#varietyprintdiv 		{text-align: left;}
	.sortprint 	  		{font-size: 1.4em;width: 100%;display: flex;padding: 10px 0px 10px 0px;margin-left: 0px !important;}
	#megamenumobitrigger 	{display: none;float: inline-start;}
	#company_img_layer 		{position: initial;}
	#company_logo 			{display: none;}
	.cookieconsent, .cookiedetails, .cookiehelp {top: 1%;}
}

@media only screen and (min-width: 600px) {
	#tcontentleft 		{padding-left:0px;}
	#vf-anchorform 	{position: absolute;}
	#vf-anchorcontent 	{position: fixed;}
	#varfinder_anchormenu {position: fixed;}
	#vf-anchor 		{position: fixed;}
	.varfimg 			{width: 390px;height: 220px;}
}

@media only screen and (max-width: 600px) {
	.beraterfr 	{flex-wrap: initial;margin-right: 0px;margin-left: 0px;}
	.beraterfrcol 	{padding-left: 0px;}
	.bkontinfo 	{font-size: 0.6rem !important;}
	.modal 		{overflow: auto;}
}

/**********************************************************************************************/
/**********************************************************************************************/
/* INTERNATIONAL */
.link_fr				{color: unset;}
.linktop_fr			{color: white;}
.link_fr:hover			{color: #9DC84B;margin-top: 10px;}
.linktop_fr:hover		{color: #19376B;margin-top: 10px;margin-bottom: 10px;}
.nav_fr				{color: white;margin-top: 10px;}
.subnav_fr			{text-decoration: underline;text-decoration-color: #9DC84B;color: #19376B;}
.subnavblocks_fr		{float: left;padding-top: 5px;padding-left: 25px;padding-right: 25px;padding-bottom: 25px;min-height: 50px;
					background-color: #F7F7F7;color: #19376B;}
.topnavlink_fr			{margin-top: 13px;margin-right: 10px;}
.navfrfixedheight 		{background-color: #F7F7F7;}
.navfrtabbackground 	{background-color: #F7F7F7;border: 1px solid #19376B;}

#pulldownmenubutton		 {margin:20px;color:#fff;background-color: #9DC84B;max-width: 270px;height: 50px;line-height: 50px;text-align: center;position: absolute;
					 right: 0px;padding: 0px 15px 0px 15px;cursor: pointer;}
#pulldownmenubuttonmobile {width: 60px;max-width: 100%;height: 253px;position: fixed;right: 0px;top:100px;cursor: pointer;z-index: 2500;color:#fff;
					 background-color: #9DC84B;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;}
#pulldownmenumobile		 {z-index: 2500;position: fixed;top: 100px;right: 0px;}
#pulldownsub1			 {background-color: #9ec64b;z-index: 2500;width: 535px;position: absolute;top: 70px;padding: 15px 0px 15px 0px;display: none;right: 20px;}
#pulldownsub1mobile		 {background-color: #9ec64b;z-index: 2500;width: 250px;position: absolute;top: 0px;padding: 15px 0px 15px 0px;display: none;right: 0px;
					 min-height: 253px;}
.pulldownsub2			 {background-color: #7ca638;z-index: 2550;width: 285px;position: absolute;top: 0px;left: 240px;padding: 15px 0px 15px 0px;
					 margin-left: 10px;color: #fff;display: none;height: -webkit-fill-available;}
.pulldownsub2mobile		 {display:none;background-color: #7ca638;padding: 0px 15px 15px 0px;line-height: 30px;font-size: 0.9em;}

.pulldownsubs,.pulldownsubsmobile {height: 42px;}
.pulldownsubs:hover,.pulldownsubsmobile:hover {background-color: #7ca638;text-decoration: none;color: #fff;}

#mainslider 			{padding-left: 0px;padding-top: 50px;}
.sliderback 			{opacity: 0.7;filter: alpha(opacity=100);background: #fff;margin: 15px;padding: 10px;height: 280px;}
.sliderpname 			{filter: alpha(opacity=100);position: absolute;top: 100px;left: 16px;background-color: #fff;font-size: 0.8em;
					padding: 15px;width: 278px;color: #000;}
.slidername 			{font-weight: 700;font-size: 1.6em;text-transform: capitalize;color: #BE2315;line-height: 1.2;float: left;}
.bluename 			{font-weight: 600;font-size: 1.0em;color: #19376B;line-height: 1.0;}
.slidermorelink 		{position: absolute;top: 230px;left: 230px;background-color: #9DC84B;color: #fff;font-size: 0.8em;padding: 3px;width: 115px;}
.carousel-caption  		{right: 30%;left: 5%;width: 310px;text-align: left;top: -35px;}
.evtitel 				{font-size: 0.9rem;}
.distri-li li			{list-style: none;}

.distri-li li:before	{content: "\f45c";width:8px;font-family: 'Font Awesome 5 Pro';font-size:12px;color:#96C038;background-color:#96C038;
					left:-30px;height: 5px;margin: 0px 10px 0px -20px;}

/**********************************************************************************************/
/**********************************************************************************************/
/* FR Hybridrechner */
.hybridblock 			{background-color: rgba(0,0,0,.03);border: 1px solid rgba(0,0,0,.125);padding: 10px;margin: 0px;height: 100%;max-height: 434px;}
#strawBonus .hybridblock {max-height: 600px;}
.hybridheader 			{padding: 25px;margin-bottom: 0px;color: #fff;font-size: 1.0rem;}
.hybridresult 			{background-color: #9DC84B;border: 1px solid rgba(0,0,0,.125);padding: 10px;margin: 0px;color:#fff;}
.hybridresult label 	{color:#fff;}
#strawBonus, #profitibilitygain {display: none;}
.hybriddiv input 		{display: initial;width: 85%;}
.hybridnowidth 		{width: initial !important;}
.hybridsteps 			{display: none;}
.hybridbtn 			{width: 100%;}
.hybridbtns 			{width: 100%;display: none;}
.hybridnocol 			{background-color: rgba(0,0,0,.125);color: #000;cursor: default;}
.hybridbutton 			{color: #fff;margin-top: 10px;margin-bottom: 10px;width: 100%;}
.hybridbtndiv 			{position: absolute;bottom: 0px;max-width: 90%;}
.hybridbtndivstraw 		{max-width: 100%;}
#hfbt3d,#hfbt4d 		{display: none;}
#chkbonus 			{display: none;}
#strawprofitability, #seedingdensresult, #wheatprofitability {background-color: #9DC84B;color: #fff;border: 0px;text-align: right;font-size: 1.3rem;}
.hybridfbutton 		{border: none;font-size: 15px;color: #000;padding: 1%;margin-right: 2%;width: 100%;text-align: center;background-color: rgba(0,0,0,.125);}
#hybridsteptxt2 		{min-height: 50px;}

/**********************************************************************************************/
/**********************************************************************************************/

/* CZ Campaigns */
.campheader {
	background-color:darkblue; 
	color: white; 
	font-size: 20px;
	font-weight: bold;
}

.camptable {
	font-size: 0.8em;
}

input.inputlang {
	width:428px;	
	float: right;
	margin-right: 25px;
	margin-bottom: 10px;

}
input.inputshort {
	width:160px;
	float: right;
	margin-right: 25px;
	margin-bottom: 10px;
}

.campback{
	background-color:#D9EAC7;
}

.campback2{
	background-color:#C6CBDE;
}

.campborder{
	border:1px solid black;
	padding-left: 5px;
}

.camplabel {
	writing-mode: vertical-rl;
	-ms-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	vertical-align: middle;
	text-align: center;
	font-size: 15px;
	font-weight: 500;
	word-break: break-word;
	height: inherit;
	width: inherit;
	background-color: darkblue;
	color: white;
	margin: 5px;
}

.camplabeltd {
	background-color: darkblue;
	color: white;
	width: 50px;
}